import styled from 'styled-components'

import { Button } from '@/ui/atoms/button'

type StyledButton = {
  $shouldShowButton: boolean
}

/**
 * styled to match legacy FES component ScrollToButton.jsx
 */
export const StyledButton = styled(Button)<StyledButton>(
  ({ $shouldShowButton }) => `
  position: fixed;

  z-index: 1000;
  cursor: pointer;
  opacity: 0.5;

  right: 0;
  bottom: ${$shouldShowButton ? 0 : '-125px'};
  margin-bottom: 4rem;
  margin-right: 3rem;
  padding: 0;

  transition:
    bottom 0.2s ease-in,
    opacity 0.2s ease-in;

  &:hover {
    opacity: 1;
  }
`
)
