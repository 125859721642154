import styled from 'styled-components'

import { MEDIA_QUERY_HP_LARGE_DESKTOP } from '@/components/homepage/styled'
import { type UseLegacyLgDesktopBreakpoint } from '@/types/common'
import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

/**
 * This is a "Gallery" header which is used to wrap the title of a gallery
 * in a container with the correct margins from the page.
 *
 * This header is used when the header and gallery (carousel/scrollable content) need
 * to be in different containers to ensure the scrolling behaviour works touching the
 * corner of the page it is placed, while the header respects the page's limits.
 */
export const GalleryHeaderWrapper = styled(Flex)<UseLegacyLgDesktopBreakpoint>(
  ({ theme }) => `
  padding: 0 ${theme.spacing.default};
  width: 100%;
  
  ${MEDIA_QUERIES(theme).medium} {
    padding: 0 ${theme.spacing.husky};
  }

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    padding: 0;
  }
`
)
