import { type CustomizedText } from '@kijiji/generated/graphql-types'

export type ContentfulDataContent = {
  content: string
  type: string
}[]

export type ContentfulJson = {
  [key: string]: {
    en: { textJson: ContentfulDataContent }
    fr?: { textJson: ContentfulDataContent }
  }
}

export const isCustomizedTextType = (item: CustomizedText | null): item is CustomizedText =>
  (item as CustomizedText).__typename === 'CustomizedText'
