type MergeFetchMoreOptions<T> = {
  prevItems?: T[] | null
  fetchMoreItems?: T[] | null
}

/**
 * Function responsible to support the FetchMore functionality
 * It will merge the previous data with the new one, returning an array with both data items
 *
 * How to use:
 *   updateQuery(prevResult, { fetchMoreResult }) {
 *    const mergedItems = mergeFetchMore<GalleryItem>({
 *      prevItems: prevResult.homepageGallery?.items,
 *      fetchMoreItems: fetchMoreResult.homepageGallery?.items,
 *    })
 *    return {
 *      __typename: 'Query',
 *      homepageGallery: { __typename: 'HomepageGallery', items: mergedItems }
 *    }
 */
export function mergeFetchMore<T>(options: MergeFetchMoreOptions<T>): T[] {
  const { prevItems = [], fetchMoreItems = [] } = options

  if (!fetchMoreItems?.length) return prevItems || []
  if (!prevItems?.length) return []

  return [...prevItems, ...fetchMoreItems]
}
