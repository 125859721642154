import { GPT_KEYS } from '@/features/advertisement/constants/gpt'
import { type GptTargeting } from '@/features/advertisement/types/adConfig'

export const getIntowowTargetingKeys = (): GptTargeting[] => {
  /**
   * Intowow benchmark/optimization traffic randomization keys
   */
  const optRatio = 0.9
  const trafficGroup = Math.random() < optRatio ? 'true' : 'false'

  const intowowKeys = [{ key: GPT_KEYS.INTOWOW, value: trafficGroup }]

  return intowowKeys
}
