import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { MEDIA_QUERY_HP_LARGE_DESKTOP } from '@/components/homepage/styled'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { type UseLegacyLgDesktopBreakpoint } from '@/types/common'
import { Flex } from '@/ui/atoms/flex'
import { TRANSITION_STYLE } from '@/ui/constants/commonStyles'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

const CentralizedBodyStyle = `
  & p {
    ${TRANSITION_STYLE}
    background-color: rgba(55,51,115, .75);
    padding: .5rem;
  }
`

const CentralizedLinkStyle = (theme: ThemeProps, isOnStaticGallery?: boolean) => `
  padding: ${isOnStaticGallery ? '4.5rem' : '8rem'} ${theme.spacing.large};

  ${MEDIA_QUERIES(theme).medium} {
    padding: ${isOnStaticGallery ? '10.5rem' : '8rem'} ${theme.spacing.large};
  }

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    padding: ${isOnStaticGallery ? '4.5rem' : '6.5rem'} ${theme.spacing.default};
  }

  ${MEDIA_QUERIES(theme).xLarge} {
    padding: ${isOnStaticGallery ? '5rem' : '6rem'} ${theme.spacing.default};
  }

  &:hover p {
    background-color: ${theme.colors.white};
    opacity: 0.9;
    color: ${theme.colors.purple.primary};
  }
`

const CentralizedCardStyle = `
  align-items: center;
  display: flex;
  justify-content: center;
`

type GalleryCardLinkProps = UseLegacyLgDesktopBreakpoint & {
  maxWidth: string[]
  centralizedTitle?: string
  isOnStaticGallery?: boolean
}
export const GalleryCardLink = styled(LinkCustom)<GalleryCardLinkProps>(
  ({ centralizedTitle, maxWidth, theme, useLegacyLgDesktopBreakpoint, isOnStaticGallery }) => `
  border-radius: ${theme.borderRadius.small};
  flex: 0 0 auto;
  overflow: hidden;
  padding: 20rem ${theme.spacing.large} ${theme.spacing.large};
  position: relative;
  width: 85vw;
  ${maxWidth[1] ? `max-width: ${maxWidth[1]};` : ''}
  
  ${MEDIA_QUERIES(theme).medium}{
    flex: unset;
    padding: 14rem ${theme.spacing.default} ${theme.spacing.default};
    width: 100%;
  }

  ${useLegacyLgDesktopBreakpoint ? MEDIA_QUERY_HP_LARGE_DESKTOP : MEDIA_QUERIES(theme).medium}{
    ${maxWidth[0] ? `max-width: ${maxWidth[0]};` : ''}
  }

  &:hover {
    text-decoration: none;
  }

  ${centralizedTitle ? CentralizedLinkStyle(theme, isOnStaticGallery) : ''}
`
)

type GalleryCardContainerProps = { centralizedTitle?: string }
export const GalleryCardContainer = styled.div<GalleryCardContainerProps>(
  ({ centralizedTitle, theme }) => `
  background-position: 50%;
  background-size: cover;
  width: 100%;

  ${centralizedTitle ? CentralizedCardStyle : ''}
  
  ${MEDIA_QUERIES(theme).medium}{
    width: 100%;
    height: 100%;
  }
`
)

type GalleryCardImageContainerProps = { position?: string }
export const GalleryCardImageContainer = styled.span<GalleryCardImageContainerProps>(
  ({ theme, position = 'center' }) => `
  padding-bottom: 40%;

  & img {
    object-fit: cover;
    object-position: ${position};
  }

  ${MEDIA_QUERIES(theme).medium}{
    padding-bottom: 25%;
  }
`
)

export const GalleryCardGradient = styled.div(
  ({ theme }) => `
  background: linear-gradient(180deg, transparent, ${theme.colors.purple.primary});
  bottom: 0;
  content: '';
  display: flex;
  height: 85%;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
`
)

type GalleryCardBodyProps = { centralizedTitle?: string }
export const GalleryCardBody = styled(Flex)<GalleryCardBodyProps>(
  ({ centralizedTitle }) => `
  position: relative;
  flex-direction: column;

  ${centralizedTitle ? CentralizedBodyStyle : ''}
`
)
