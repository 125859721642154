import {
  type FeedPageInfo,
  type FeedResults,
  GetHomeFeedAdsDocument,
  useGetHomeFeedAdsQuery,
} from '@kijiji/generated/graphql-types'
import { type Session } from 'next-auth'
import { useTranslation } from 'next-i18next'
import { type FC, Fragment } from 'react'
import { useInView } from 'react-intersection-observer'
import { useTheme } from 'styled-components'

import {
  homepageFeedAdTracking,
  homepageFeedBrandedCategoryTracking,
  homepageFeedLoadMoreTracking,
  homepageFeedPopularCategoryTracking,
} from '@/components/homepage/homepage-feed/feedTracking'
import {
  homepageFeedPopularCategoryImageSource,
  showHomeFeedAdvertisement,
  showHomepageGalleryAfterFeedItem,
} from '@/components/homepage/homepage-feed/helpers'
import { HomepageFeedAdSlot } from '@/components/homepage/homepage-feed/HomepageFeedAdSlot'
import {
  HomeFeedListContainer,
  HomepageFeedFullLine,
  HomepageFeedWrapper,
} from '@/components/homepage/homepage-feed/styled'
import {
  type HomepageGalleryProps,
  HomepageGallery,
} from '@/components/homepage/homepage-gallery/HomepageGallery'
import { LoadingVerticalGallery } from '@/components/shared/loading-gallery/LoadingVerticalGallery'
import { VerticalListingCard } from '@/components/shared/vertical-listing-card/VerticalListingCard'
import { useLocale } from '@/hooks/useLocale'
import { isBrandedCategoryItem, isFeedItem, isPopularCategoryItem } from '@/types/homepage'
import { Button } from '@/ui/atoms/button'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Loading } from '@/ui/atoms/loading'
import { Spacing } from '@/ui/atoms/spacing'

export type HomepageFeedProps = HomepageGalleryProps & {
  correlationId: string
  feedPageInfo?: FeedPageInfo | null
  feedResults: FeedResults[]
  handleLoadMore: (loadMoreClick?: boolean) => Promise<void>
  loading: boolean
  shouldAutoFetchMore?: boolean
  userData?: Session | null
  loadingFeed?: boolean
}

export const HomepageFeed: FC<HomepageFeedProps> = ({
  correlationId,
  feedPageInfo,
  feedResults,
  getSeoUrl,
  handleLoadMore,
  loading,
  loadingFeed,
  locationId,
  locationName,
  shouldAutoFetchMore,
  userAgent,
  userData,
}) => {
  const { ref } = useInView({
    /** It will trigger fetchMore when screen is on 65% of the container */
    threshold: 0.65,
    /** Ensure it will only trigger once, not in every pass */
    initialInView: true,
    onChange(inView) {
      if (inView) {
        if (feedPageInfo?.hasNextPage) {
          handleLoadMore()
        }
      }
    },
  })

  const { routeLocale } = useLocale()
  const { colors, spacing } = useTheme()
  const { t } = useTranslation('home')

  const { client } = useGetHomeFeedAdsQuery()

  const handleLoadMoreClick = (position: number) => {
    homepageFeedLoadMoreTracking(position)
    handleLoadMore(true)
  }

  const resultsLength = feedResults.length

  if (!userData?.user) null

  return (
    <HomepageFeedWrapper data-testid="homepage-infinite-feed">
      {loadingFeed ? (
        <div data-testid="hp-infinite-feed-loading">
          <LoadingVerticalGallery hasLoadingTitle itemsCount={40} />
        </div>
      ) : (
        <>
          <HeadlineText as="h2" color={colors.grey.primary} size="medium" weight="regular">
            {t('homepage_feed.title', { profileName: userData?.user.name })}
          </HeadlineText>

          <HomeFeedListContainer>
            {feedResults.map((item, index) => {
              const itemPosition = index + 1
              const itemsPerRow = 4
              const fetchMoreIntersection = resultsLength - itemsPerRow * 2 === itemPosition
              const feedAd = showHomeFeedAdvertisement(itemPosition)
              const seoUrl = `${item.seoUrl}?cid=${correlationId}`

              if (feedAd.shouldShowAd) {
                client.cache.writeQuery({
                  query: GetHomeFeedAdsDocument,
                  data: {
                    homeFeed: {
                      adSlots: feedAd.adNumber,
                    },
                  },
                })
              }

              let card

              if (isPopularCategoryItem(item)) {
                card = (
                  <VerticalListingCard
                    description={item.title}
                    handleClick={() => homepageFeedPopularCategoryTracking(item.id, itemPosition)}
                    linkTarget="_blank"
                    listingImage={{
                      alt: '',
                      src: homepageFeedPopularCategoryImageSource(item.id, routeLocale),
                    }}
                    listingImageSize="regular"
                    seoUrl={seoUrl}
                    title={t('homepage_feed.popular_categories.title').toUpperCase()}
                    variant="category"
                  />
                )
              }

              if (isBrandedCategoryItem(item)) {
                card = (
                  <VerticalListingCard
                    description={item.title}
                    handleClick={() => homepageFeedBrandedCategoryTracking(item.id)}
                    linkTarget="_blank"
                    listingImage={{ alt: '', src: item.imageUrl }}
                    listingImageSize="regular"
                    seoUrl={seoUrl}
                    title={t('homepage_feed.popular_categories.title').toUpperCase()}
                    variant="category"
                  />
                )
              }

              if (isFeedItem(item)) {
                card = (
                  <VerticalListingCard
                    allowFavourite
                    handleClick={() => homepageFeedAdTracking(item.id, item.type, itemPosition)}
                    linkTarget="_blank"
                    listingId={item.id}
                    listingImage={{ alt: '', src: item.imageUrls?.[0] }}
                    listingImageSize="regular"
                    price={item.price?.amount}
                    seoUrl={seoUrl}
                    title={item.title}
                  />
                )
              }

              return (
                <Fragment key={`hp-feed-ad-${item.id}-${itemPosition}`}>
                  <div>
                    {card}

                    {/* Add intersectional observer ref to second to last item of the list */}
                    {fetchMoreIntersection && <div id="load-more-intersection" ref={ref} />}
                  </div>

                  {showHomepageGalleryAfterFeedItem(itemPosition) && (
                    <HomepageFeedFullLine isGallery>
                      <HomepageGallery
                        getSeoUrl={getSeoUrl}
                        locationId={locationId}
                        userAgent={userAgent}
                        locationName={locationName}
                      />
                    </HomepageFeedFullLine>
                  )}

                  {feedAd.shouldShowAd && (
                    <HomepageFeedFullLine id={`advertisement-feed-${feedAd.adNumber}`}>
                      <HomepageFeedAdSlot count={feedAd.adNumber} />

                      {/* Should only show the load item after the last element */}
                      {!shouldAutoFetchMore && feedResults.length === itemPosition && (
                        <Spacing mTop={spacing.large}>
                          <Flex alignItems="center" justifyContent="center">
                            <Button
                              variant="secondary"
                              size="small"
                              onClick={() => handleLoadMoreClick(itemPosition)}
                            >
                              {t('homepage_feed.load_more')}
                            </Button>
                          </Flex>
                        </Spacing>
                      )}
                    </HomepageFeedFullLine>
                  )}
                </Fragment>
              )
            })}
          </HomeFeedListContainer>

          {loading && (
            <Spacing mTop={spacing.husky}>
              <Flex alignItems="center" justifyContent="center">
                <Loading size="medium" />
              </Flex>
            </Spacing>
          )}
        </>
      )}
    </HomepageFeedWrapper>
  )
}
