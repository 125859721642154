import styled from 'styled-components'

import { MEDIA_QUERY_HP_LARGE_DESKTOP } from '@/components/homepage/styled'
import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

/**
 * This AppMarketingBanner section needs a couple of extra breakpoints to
 * allow proper placing of the images/text and match legacy
 *
 * If this section is redesign - check with UI team to follow breakpoints from theme
 */
const BANNER_EARLY_TABLET_BREAKPOINT = '787px'
const BANNER_MOBILE_BREAKPOINT = '399px'

export const AppMarketingBannerContainer = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.purple.primary};
  overflow: hidden;
  padding: ${theme.spacing.xLarge};
  padding-bottom: 16rem;
  position: relative;
  width: 100%;

  @media screen and (min-width:${BANNER_EARLY_TABLET_BREAKPOINT}) {
    overflow: visible;
    padding: ${theme.spacing.xLarge};
    
    & h2 {
      max-width: 65%;
    }
  }

  ${MEDIA_QUERIES(theme).large}{
    padding: ${theme.spacing.husky} 32rem ${theme.spacing.husky} 9.5rem;

    & h2 {
      max-width: 80%;
    }
  }
`
)

export const AppMarketingBannerLinksContainer = styled(Flex)(
  ({ theme }) => `
  height: 4.6rem;
  min-width: 14rem;
  row-gap: ${theme.spacing.defaultSmall};
  
  & svg {
    height: 4.6rem;
  }
`
)

export const AppMarketingBannerImageContainer = styled.div(
  ({ theme }) => `
  bottom: -13rem;
  max-width: 33.5rem;
  min-height: 22.5rem;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  width: 100%;

  & image {
    object-fit: contain;
  }

  @media screen and (min-width:${BANNER_MOBILE_BREAKPOINT}){
    bottom: -8rem;
  }

  @media screen and (min-width:${BANNER_EARLY_TABLET_BREAKPOINT}) {
    bottom: 0;
    max-width: 28.5rem;
    min-height: 22.2rem;
    right: 0;
    transform: none;
  }
  
  ${MEDIA_QUERIES(theme).large}{
    max-width: 31.5rem;
    min-height: 25.2rem;
    right: ${theme.spacing.giant};
  }
  
  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    right: 17rem;
  }
`
)
