import { useTheme } from 'styled-components'

import { HOMEPAGE_GPT_ID } from '@/components-page/homepage/advertisement/homepageAdSlots'
import { StyledGptRightrailBottom } from '@/components-page/homepage/advertisement/styled'
import { triggerManualAdRefresh } from '@/features/advertisement/utils/triggerManualAdRefresh'
import { useBreakpointChange } from '@/hooks/useBreakpointChange'

export const GptRightrailBottom = () => {
  const { breakpoints } = useTheme()

  useBreakpointChange([breakpoints.large, breakpoints.medium], () => {
    triggerManualAdRefresh(HOMEPAGE_GPT_ID.RIGHTRAIL_BOTTOM)
  })

  return (
    <StyledGptRightrailBottom
      id={HOMEPAGE_GPT_ID.RIGHTRAIL_BOTTOM}
      data-testid={HOMEPAGE_GPT_ID.RIGHTRAIL_BOTTOM}
    />
  )
}
