import { HTMLAttributes } from 'react'

import { PanelContainer } from './styled'

export type PanelProps = HTMLAttributes<HTMLDivElement> & {
  $isFullWidth?: boolean
  $alignItems?: `flex-start` | `flex-end` | `center` | `baseline` | `stretch`
}

/**
 * @description Basic white panel with rounded corners and a box shadow
 *
 * @param $alignItems - Defines items horizontal  alignment
 * @param $isFullWidth - Defines if panel will take full parent width
 */

export const Panel = PanelContainer
