import styled from 'styled-components'

type TextFragmentAssemblerSpanProps = {
  color: string
  isBold: boolean
}
export const TextFragmentAssemblerSpan = styled.span<TextFragmentAssemblerSpanProps>(
  ({ color, isBold }) => `
  color: ${color};
  fontWeight: ${isBold ? 'bold' : 'inherit'};
`
)
