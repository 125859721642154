import { HideAtOrLargerHP } from '@/components/homepage/shared/ShowAtOrLargerHP'
import { AdChoicesLink } from '@/components-page/homepage/advertisement/AdChoicesLink'
import { HOMEPAGE_GPT_ID } from '@/components-page/homepage/advertisement/homepageAdSlots'
import {
  GptRightrailMarketingWrapper,
  StyledGptRightrailMarketing,
} from '@/components-page/homepage/advertisement/styled'
import { LEGACY_HP_LG_BREAKPOINT } from '@/constants/pageSettings'

export const GptRightrailMarketing = () => {
  return (
    <GptRightrailMarketingWrapper>
      <HideAtOrLargerHP breakpoint={LEGACY_HP_LG_BREAKPOINT}>
        <AdChoicesLink />
      </HideAtOrLargerHP>

      <StyledGptRightrailMarketing
        id={HOMEPAGE_GPT_ID.RIGHTRAIL_MARKETING}
        data-testid={HOMEPAGE_GPT_ID.RIGHTRAIL_MARKETING}
      />
    </GptRightrailMarketingWrapper>
  )
}
