import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgBackToTop: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <clipPath id="back-to-top_svg__a">
        <path d="M2 2h20v20H2z" />
      </clipPath>
      <g clipPath="url(#back-to-top_svg__a)">
        <path d="M12 2a10 10 0 100 20 10 10 0 000-20z" fill="#8e909b" />
        <g fill="#fff">
          <path d="M15.35 13.35a.481.481 0 01-.7 0l-2.15-2.14V17a.5.5 0 11-1 0v-5.79l-2.15 2.14a.48.48 0 01-.7 0 .48.48 0 010-.7l3-3a.36.36 0 01.16-.11.5.5 0 01.38 0 .36.36 0 01.16.11l3 3a.481.481 0 010 .7zM15.5 7.5h-7a.5.5 0 110-1h7a.5.5 0 010 1z" />
        </g>
      </g>
    </Icon>
  )
}
export default SvgBackToTop
