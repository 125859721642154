import styled from 'styled-components'

import { MEDIA_QUERY_HP_LARGE_DESKTOP } from '@/components/homepage/styled'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { Flex } from '@/ui/atoms/flex'

export const PopularCategoriesHideOnBreakpoint = styled.span`
  display: none;

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    display: inline;
  }
`

export const PopularCategoriesHeaderContainer = styled(Flex)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.defaultSmall};
  width: 100%;

  & > a {
    padding: .8rem 0;
  }

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    align-items: center;
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
  }
`
)

export const PopularCategoriesHeaderLink = styled(LinkCustom)(
  ({ theme }) => `
  color: ${theme.colors.blue.dark1};
  font-size: 1.5rem;
`
)
