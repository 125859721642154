import { useGetRecentlyViewedListingsQuery } from '@kijiji/generated/graphql-types'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { type ReactNode } from 'react'
import { useTheme } from 'styled-components'

import { GalleryHeaderWrapper } from '@/components/shared/gallery/GalleryHeaderWrapper'
import { GalleryWrapper } from '@/components/shared/gallery/GalleryWrapper'
import { LegacyCarousel } from '@/components/shared/legacy-carousel/LegacyCarousel'
import { VerticalListingCard } from '@/components/shared/vertical-listing-card/VerticalListingCard'
import { isUserAuthenticated } from '@/features/auth/constants/user'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

export const RecentlyViewedAds = () => {
  const { status, data: userData } = useSession()
  const userId = parseInt(`${userData?.user.sub}`)

  const { colors, spacing } = useTheme()
  const { t } = useTranslation('home')

  /**
   * There is no pagination or fetchMore defined for this carousel
   */
  const { data } = useGetRecentlyViewedListingsQuery({
    skip: !isUserAuthenticated(status) || !userId,
    ssr: false,
    variables: { userId },
    onCompleted: () => {
      /** there are no impressions tracking on this gallery */
    },
  })

  const slides: ReactNode[] =
    data?.user?.recentlyViewedListings?.map((item) => {
      if (!item) return null

      const handleListingClickTracking = () => {
        /** The position of the ad click is not tracked on this section */
        trackEvent({
          action: GA_EVENT.RecentAdClick,
          label: `AdID=${item.id};module=recentlyViewed;`,
        })
      }
      /**
       * the request is returning imageUrls appended with `?rule=kijijica-64-jpg`
       * but it should be `?rule=kijijica-300-jpg`
       * we need to correct to avoid a blurry image
       * we're doing the same thing on FES
       */
      const imageUrl = item.imageUrls?.[0]
        ? item.imageUrls?.[0].replace(/rule=kijijica-\d+-jpg/g, 'rule=kijijica-300-jpg')
        : ''

      return (
        <VerticalListingCard
          key={`hp-recently-viewed-${item.id}`}
          listingImage={{ alt: '', src: imageUrl }}
          listingImageSize="regular"
          handleClick={handleListingClickTracking}
          price={item.price?.amount}
          seoUrl={item.seoUrl}
          title={item.title}
        />
      )
    }) || []

  const hasEnoughAds = slides.length >= 5
  if (!hasEnoughAds) return null

  return (
    <Spacing mBottom={spacing.husky}>
      <GalleryWrapper data-testid="hp-recently-viewed-section">
        <Spacing mBottom={spacing.defaultSmall} data-testid="hp-recently-viewed-gallery-header">
          <GalleryHeaderWrapper justifyContent="space-between" alignItems="center">
            <HeadlineText as="h2" color={colors.grey.primary} size="medium" weight="regular">
              {t('recently_viewed.title')}
            </HeadlineText>
          </GalleryHeaderWrapper>
        </Spacing>

        <Flex>
          <LegacyCarousel
            name="hp-recently-viewed-carousel"
            shouldShowArrows={[true, false, false]}
            slides={slides}
            slidesToScroll={[2.5, 1.5, 1.5]}
            slidesToShow={[6, 4.2, 2.2]}
            useLegacyLgDesktopBreakpoint
          />
        </Flex>
      </GalleryWrapper>
    </Spacing>
  )
}
