import debounce from 'lodash/debounce'
import { useEffect, useRef } from 'react'

import { useEnsureClient } from '@/components/shared/client-render'

const getCurrentBreakpoint = (breakpoints: number[]) =>
  // sort from largest to smallest breakpoint
  breakpoints.sort((a, b) => b - a).find((breakpoint) => breakpoint < window.innerWidth)

/**
 * Hook to call a function whenever a breakpoint is crossed
 * @param breakpoints - array of breakpoints
 * @param callback - function to call when breakpoint changes
 * @param debounceTime - debounce time in ms
 */
export const useBreakpointChange = (
  breakpoints: number[],
  callback: () => void,
  debounceTime: number = 300
) => {
  useEnsureClient()
  const currentBreakpointRef = useRef(getCurrentBreakpoint(breakpoints))

  useEffect(() => {
    const handleResize = debounce(() => {
      // get the current breakpoint and compare it to the previous one
      const currentBreakpoint = getCurrentBreakpoint(breakpoints)
      if (currentBreakpoint !== currentBreakpointRef.current) {
        callback()
        currentBreakpointRef.current = currentBreakpoint
      }
    }, debounceTime)

    window.addEventListener('resize', handleResize)

    return () => {
      // cleanup on unmount
      handleResize.cancel()
      window.removeEventListener('resize', handleResize)
    }
  }, [breakpoints, callback, debounceTime])
}
