import { type TFunction } from 'next-i18next'

/**
 * Formats a price drop notification message for both English and French locales.
 * Extracts the price from the original message (supports both $ followed by numbers and numbers followed by $).
 * Uses the provided translation function `t` to return the localized notification.
 *
 * @param {string} message - The original message containing the price drop details.
 * @param {TFunction} t - A translation function (such as i18n's `t`) used to translate the message into the correct locale.
 *
 * @throws {Error} If no price is found in the message.
 *
 * @returns {string} - A formatted and localized message with the extracted price.
 *
 * @example
 * // Example usage in English
 * const englishMessage = 'The price of one of your favourited items just dropped! “2012 Yamaha R6 Cool Bike” is now $8,400. Check it out.';
 * const result = formatPriceDropMessage(englishMessage, t);
 * console.log(result); // "The seller dropped the price for a listing you favourited. It is now 8400. Check it out!"
 *
 * @example
 * // Example usage in French
 * const frenchMessage = 'Le prix de l’un de vos articles préférés vient d’être baissé! “2012 Yamaha R6 Cool Bike” est maintenant à 8 400 $. Jetez-y un coup d’œil!';
 * const result = formatPriceDropMessage(frenchMessage, t);
 * console.log(result); // "Le vendeur a baissé le prix d'un article que vous avez favorisé. Il est maintenant à 8400. Jetez-y un coup d’œil!"
 */
export const formatPriceDropMessage = (message: string, t: TFunction) => {
  const englishPricePattern = /\$\d{1,3}(?:,\d{3})*(?:\.\d{2})?/
  const frenchPricePattern = /\d{1,3}(?: \d{3})*(?:,\d{2})?\s?\$/

  const englishPriceMatch = message.match(englishPricePattern)
  if (englishPriceMatch) {
    const price = englishPriceMatch[0].replace('$', '').trim()
    return t('home:price_drop.notification_body', { price })
  }

  const frenchPriceMatch = message.match(frenchPricePattern)
  if (frenchPriceMatch) {
    const price = frenchPriceMatch[0].replace(/\s?\$/, '').trim()
    return t('home:price_drop.notification_body', { price })
  }
  // in case the match doesn't happen return the same message as returned by BE.
  return message
}
