import { useTranslation } from 'next-i18next'

import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'

export const HomepageGalleryTooltip = () => {
  const { t } = useTranslation('home')

  const handleLinkTracking = () => {
    trackEvent({ action: GA_EVENT.HelpDeskClick, label: 'btn=learnmore;article=LearnMoreHPG;' })
  }

  return (
    <Flex flexDirection="column">
      <BodyText weight="bold" size="small" id="hp-gallery-tooltip-title">
        {t('gallery.learn_more.title')}
      </BodyText>

      <Spacing mTop=".5rem" mBottom="0.5rem">
        <BodyText style={{ minWidth: '20rem' }} size="small">
          {t('gallery.learn_more.subtitle')}
        </BodyText>
      </Spacing>

      <LinkCustom
        href={t('gallery.learn_more.url')}
        onClick={handleLinkTracking}
        openInNewTab
        rel="noopener noreferrer"
        size="small"
        variant="secondary"
      >
        {t('gallery.learn_more.label')}
      </LinkCustom>
    </Flex>
  )
}
