import { ContentModuleId, useGetContentModuleQuery } from '@kijiji/generated/graphql-types'
import { type FC } from 'react'

import { BrowseHero } from '@/components/shared/cms-modules/browse-hero/BrowseHero'
import { isValidLocation } from '@/domain/location/isValidLocation'

type HeroCMSBanner = {
  locationId: number
}

export const HeroCMSBanner: FC<HeroCMSBanner> = ({ locationId }) => {
  const { data } = useGetContentModuleQuery({
    fetchPolicy: 'cache-first',
    skip: !isValidLocation(locationId),
    variables: {
      locationId,
      moduleId: ContentModuleId.WelcomePopularBanner,
    },
  })

  return <BrowseHero contentModule={data?.contentModule} />
}
