import { ShowAtOrLargerHP } from '@/components/homepage/shared/ShowAtOrLargerHP'
import { AdChoicesLink } from '@/components-page/homepage/advertisement/AdChoicesLink'
import { HOMEPAGE_GPT_ID } from '@/components-page/homepage/advertisement/homepageAdSlots'
import { StyledGptRightrailTop } from '@/components-page/homepage/advertisement/styled'
import { LEGACY_HP_LG_BREAKPOINT } from '@/constants/pageSettings'
import { triggerManualAdRefresh } from '@/features/advertisement/utils/triggerManualAdRefresh'
import { useBreakpointChange } from '@/hooks/useBreakpointChange'

export const GptRightrailTop = () => {
  useBreakpointChange([LEGACY_HP_LG_BREAKPOINT], () => {
    triggerManualAdRefresh(HOMEPAGE_GPT_ID.RIGHTRAIL_TOP)
  })

  return (
    <>
      <ShowAtOrLargerHP breakpoint={LEGACY_HP_LG_BREAKPOINT}>
        <AdChoicesLink />
      </ShowAtOrLargerHP>

      <StyledGptRightrailTop
        id={HOMEPAGE_GPT_ID.RIGHTRAIL_TOP}
        data-testid={HOMEPAGE_GPT_ID.RIGHTRAIL_TOP}
      />
    </>
  )
}
