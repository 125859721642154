import { HOMEPAGE_GPT_ID } from '@/components-page/homepage/advertisement/homepageAdSlots'
import { StyledGptLeaderBase } from '@/components-page/homepage/advertisement/styled'

export const GptLeaderBottom = () => {
  return (
    <StyledGptLeaderBase
      id={HOMEPAGE_GPT_ID.LEADER_BOTTOM}
      data-testid={HOMEPAGE_GPT_ID.LEADER_BOTTOM}
    />
  )
}
