import BackToTopIcon from '@kijiji/icons/src/icons/BackToTop'
import { useEffect, useState } from 'react'

import { StyledButton } from './styled'

export type ScrollToTopProps = {
  visibilityThreshold?: number
  scrollToPosition?: number
}

/**
 * A scroll-to-top button that appears in the bottom-right corner
 * when the user scrolls past a certain point
 * based on legacy FES component ScrollToButton.jsx
 * @param {number} visibilityThreshold vertical scroll position (px) at which the button should appear; default is 1500
 * @param {number} scrollToPosition vertical position (px) to scroll back to; default is 0, i.e. top of the page
 * @returns {JSX.Element} ScrollToTop button
 */
export const ScrollToTopButton = ({
  visibilityThreshold = 1500,
  scrollToPosition = 0,
}: ScrollToTopProps) => {
  const [shouldShowButton, setShouldShowButton] = useState(false)

  useEffect(() => {
    const toggleVisibility = () =>
      setShouldShowButton(window.scrollY > visibilityThreshold)

    window.addEventListener('scroll', toggleVisibility)
    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: scrollToPosition,
      behavior: 'smooth',
    })
  }

  return (
    <StyledButton
      aria-label="Scroll to top of page"
      onClick={scrollToTop}
      $shouldShowButton={shouldShowButton}
      variant="tertiary"
      size="small"
    >
      <BackToTopIcon width="60" />
    </StyledButton>
  )
}
