import styled from 'styled-components'

import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { PageContainer } from '@/components/shared/page-container'
import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

export const BrowseHeroSection = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.purple.primary};
  margin-bottom: ${theme.spacing.xLarge};
  width: 100%;
`
)

export const BrowseHeroContainer = styled(PageContainer)(
  ({ theme }) => `
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  margin-top: 0;
  min-height: 28rem;
  width: 100%;
  
  ${MEDIA_QUERIES(theme).mediumSmall}{
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    min-height: 27rem;
  }

  ${MEDIA_QUERIES(theme).medium}{
    grid-template-columns: .55fr 1fr;
    grid-template-rows: 1fr;
    min-height: 28rem;
  }
  
  ${MEDIA_QUERIES(theme).xLarge}{
    grid-template-columns: .5fr 1fr;
    min-height: 23rem;
  }
`
)

export const BrowseHeroTitleContainer = styled.div(
  ({ theme }) => `
  align-items: center;
  display: flex;
  padding: ${theme.spacing.default} ${theme.spacing.default} 0;
    
  & h1 {
    text-align: center;
  }
  
  ${MEDIA_QUERIES(theme).medium} {
    padding: ${theme.spacing.default} ${theme.spacing.xLarge};

    & h1 {
      text-align: start;
    }
  }
`
)

export const BrowseHeroTilesContainer = styled.div(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing.default};
  height: 100%;
  padding: ${theme.spacing.large} 0 ${theme.spacing.large};
  width: 100%;
  
  ${MEDIA_QUERIES(theme).medium} {
    padding: ${theme.spacing.default} 0 ${theme.spacing.default};
  }

  ${MEDIA_QUERIES(theme).large} {
    padding: ${theme.spacing.large} 0 ${theme.spacing.large} ${theme.spacing.xLarge};  
  }
`
)

export const TileModule = styled(Flex)(
  ({ theme }) => `
  align-items: flex-end;
  background-color: ${theme.colors.purple.primary};
  border-radius: ${theme.borderRadius.large};
  box-shadow: 0 2px 10px 0 rgba(11,12,31,.5);
  overflow: hidden;
  padding-right: ${theme.spacing.default};
  position: relative;
  width: 100%;

  & img {
    object-position: center;
    object-fit: cover;
  }

  ${MEDIA_QUERIES(theme).medium}{
    padding-right: 0;
  }
`
)

export const TileLink = styled(LinkCustom)(
  ({ theme }) => `
  align-items: flex-end;
  display: flex;
  height: 100%;
  text-decoration: none;
  width: 100%;
  
  &:hover span, 
  &:focus span {
    background: hsla(0,0%,100%,.9);
    color: ${theme.colors.purple.primary};
  }
  
  ${MEDIA_QUERIES(theme).medium}{
    justify-content: center;
    align-items: center;

    &:hover span, 
    &:focus span {
      background: ${theme.colors.purple.dark1};
      color: ${theme.colors.white};
    }
  }
`
)

export const TileLinkContainer = styled.span(
  ({ theme }) => `
  ${styleFromTypography(theme.typography.body.small)};
  background: rgba(23, 25, 53, 0.5);
  color: ${theme.colors.white};
  font-weight: 400;
  opacity: 0.9;
  padding: ${theme.spacing.mini} ${theme.spacing.defaultSmall};
 
  ${MEDIA_QUERIES(theme).medium}{
    ${styleFromTypography(theme.typography.body.large)};
    background: ${theme.colors.white};
    color: ${theme.colors.purple.primary};
  }

  ${MEDIA_QUERIES(theme).large}{
    ${styleFromTypography(theme.typography.body.large)};
  }
`
)
