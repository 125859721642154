import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'

/**
 * Handles popular section tracking
 * This will be re-used for all 3 popular section as well as the "Browse All" links
 */
export const handlePopularCategoryTracking = (categoryId: number, isMoVeXPromo?: boolean) => {
  /** MoVe xPromo shouldn't trigger event with label, just the action */
  const params = isMoVeXPromo ? {} : { label: `catId=${categoryId};btn=popular` }

  trackEvent({ action: GA_EVENT.CategorySelected, ...params })
}
