import styled from 'styled-components'

import { MEDIA_QUERY_HP_LARGE_DESKTOP } from '@/components/homepage/styled'
import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

/**
 * Scrollable container to wrap the gallery items
 * this will automatically change the section from a inline view on bigger screens
 * to a scrolling container after "medium" breakpoint
 */
export const GalleryScrollableContainer = styled(Flex)(
  ({ theme }) => `
  flex-wrap: nowrap;
  gap: ${theme.spacing.defaultSmall};
  overflow-y: auto;
  padding-left: ${theme.spacing.default};
  
  ${MEDIA_QUERIES(theme).medium} {
    gap: ${theme.spacing.default};
    padding: 0 ${theme.spacing.husky} 0;
  }

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    padding: 0;
  }
`
)
