import { type FC } from 'react'

import { type GalleryCardProps, GalleryCard } from '@/components/shared/gallery/GalleryCard'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { type BrandedTileData } from '@/types/customCampaigns'

/**
 * Allows passing any custom props the gallery needs to render
 * properly within the section
 */
export type BrandedTileProps = Partial<GalleryCardProps> & {
  /**
   * Branded tile campaign data
   */
  campaign: BrandedTileData
}

export const BrandedTile: FC<BrandedTileProps> = ({ campaign, ...rest }) => {
  const handleBrandedTileTracking = () => {
    trackEvent({
      action: GA_EVENT.BrandedCategorySelected,
      label: `btn=popular;to=BrandedSearch;campaignID=${campaign.id}`,
    })
  }

  return (
    <GalleryCard
      centralizedTitle={campaign.title}
      href={campaign.srpUrl || campaign.internalUrl || ''}
      image={{ alt: '', src: campaign.backgroundLoggedOut }}
      index={1}
      onClick={handleBrandedTileTracking}
      useLegacyLgDesktopBreakpoint
      {...rest}
    />
  )
}
