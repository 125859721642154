import {
  ALL_CANADA_LABEL_EN,
  ALL_CANADA_LABEL_FR,
  ALL_CANADA_LOCATION_ID,
} from '@/constants/location'
import locationTree from '@/constants/location/locationTree.json'
import { type LocationPath } from '@/types/location'

const ALL_CANADA_LOCATION_PATH: LocationPath = {
  id: ALL_CANADA_LOCATION_ID,
  name: { en_CA: ALL_CANADA_LABEL_EN, fr_CA: ALL_CANADA_LABEL_FR },
}

/**
 * Mounts the location path without needing to call for an API
 */
export const getLocationPath = (locationId: number): LocationPath[] => {
  const path: LocationPath[] = []
  let currentLocation = locationTree.find((item) => item.id === locationId)

  while (currentLocation) {
    path.unshift({ id: currentLocation.id, name: currentLocation.name })
    if (currentLocation.parentId === 0) {
      break
    }

    currentLocation = locationTree.find((item) => item.id === currentLocation?.parentId)
  }

  if (!path.length || currentLocation?.parentId === 0) {
    path.unshift(ALL_CANADA_LOCATION_PATH)
  }

  return path
}
