import { type FC, type PropsWithChildren } from 'react'
import { AdvertisingProvider } from 'react-advertising'

import { AdSenseScripts } from '@/features/advertisement/components/adsense/AdSenseScripts'
import { AmazonTamScripts } from '@/features/advertisement/components/amazon-tam/amazonTamScripts'
import { BlockthroughScripts } from '@/features/advertisement/components/blockthrough/BlockthroughScripts'
import { PrebidScripts } from '@/features/advertisement/components/prebid/PrebidScripts'
import { type AdConfig } from '@/features/advertisement/types/adConfig'

type AdsWrapperProps = PropsWithChildren & {
  gptConfig?: AdConfig
  prebidConfig?: { setupPrebid: () => void }[]
  skipAdsense?: boolean
  skipBlockthrough?: boolean
}

export const AdsWrapper: FC<AdsWrapperProps> = ({
  children,
  gptConfig,
  prebidConfig,
  skipAdsense,
  skipBlockthrough,
}) => {
  return (
    <>
      <AdvertisingProvider config={gptConfig} plugins={prebidConfig}>
        {children}
      </AdvertisingProvider>

      <PrebidScripts />
      <AmazonTamScripts />

      {skipAdsense ? null : <AdSenseScripts />}
      {skipBlockthrough ? null : <BlockthroughScripts />}
    </>
  )
}
