import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { handlePopularCategoryTracking } from '@/components/homepage/popular-categories/domain'
import {
  PopularCategoriesHeaderContainer,
  PopularCategoriesHeaderLink,
  PopularCategoriesHideOnBreakpoint,
} from '@/components/homepage/popular-categories/styled'
import { HeadlineText } from '@/ui/atoms/headline-text'

export type PopularCategoriesHeaderProps = {
  /**
   * Category ID to be browsed when clicking on the "browse" link
   */
  browseAllCategoryId: number
  /**
   * Function to get the correct SEO url based on the category Id
   */
  getSeoUrl: (categoryId: number, index?: number) => string
  /**
   * Defines if user is on a region location
   */
  isRegionLocation?: boolean
  /**
   * Defines the location name user is currently searching on
   */
  locationName?: string | null
  /**
   * Defines which section is being rendered
   * It will map to the correct translations
   */
  sectionKey: 'autos' | 'real_estate' | 'buy_sell'
}

export const PopularCategoriesHeader: FC<PopularCategoriesHeaderProps> = ({
  browseAllCategoryId,
  getSeoUrl,
  isRegionLocation,
  locationName,
  sectionKey,
}) => {
  const { colors, spacing } = useTheme()
  const { t } = useTranslation('home')

  return (
    <PopularCategoriesHeaderContainer
      alignItems="flex-start"
      data-testid="popular-categories-header"
      flexDirection="column"
      gap={spacing.mini}
    >
      <HeadlineText as="h2" color={colors.grey.primary} size="medium" weight="regular">
        <PopularCategoriesHideOnBreakpoint>
          {t('popular_categories.header.prefix')}
        </PopularCategoriesHideOnBreakpoint>{' '}
        {t(`popular_categories.header.${sectionKey}_category`)}{' '}
        {isRegionLocation ? t('popular_categories.header.suffix', { locationName }) : null}
      </HeadlineText>

      <PopularCategoriesHeaderLink
        href={getSeoUrl(browseAllCategoryId)}
        size="small"
        weight="regular"
        onClick={() => handlePopularCategoryTracking(browseAllCategoryId)}
      >
        {t('popular_categories.header.link.label')}{' '}
        <PopularCategoriesHideOnBreakpoint>
          {t(`popular_categories.header.${sectionKey}_category`)}
        </PopularCategoriesHideOnBreakpoint>
      </PopularCategoriesHeaderLink>
    </PopularCategoriesHeaderContainer>
  )
}
