import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { LoadingListingCard } from '@/components/shared/loading-gallery/LoadingListingCard'
import { LoadingVerticalGalleryContainer } from '@/components/shared/loading-gallery/styled'
import { Flex } from '@/ui/atoms/flex'
import { Skeleton } from '@/ui/atoms/skeleton'

export type LoadingVerticalGalleryProps = {
  /**
   * Should show loading skeleton for title above the gallery
   */
  hasLoadingTitle?: boolean
  /**
   * Defines how many items in the gallery loading state
   * @default 6
   */
  itemsCount?: number
}

export const LoadingVerticalGallery: FC<LoadingVerticalGalleryProps> = ({
  hasLoadingTitle,
  itemsCount = 6,
}) => {
  const { spacing } = useTheme()

  return (
    <Flex flexDirection="column" gap={spacing.defaultSmall}>
      {hasLoadingTitle && (
        <Skeleton
          count={1}
          data-testid="loading-vertical-gallery-title"
          variant="inline"
          width="25rem"
        />
      )}

      <LoadingVerticalGalleryContainer>
        {Array.from({ length: itemsCount }).map((_, index) => (
          <LoadingListingCard key={`loading-vertical-gallery-slides-${index}`} />
        ))}
      </LoadingVerticalGalleryContainer>
    </Flex>
  )
}
