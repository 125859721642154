import styled from 'styled-components'

import { MEDIA_QUERY_HP_LARGE_DESKTOP } from '@/components/homepage/styled'
import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const GalleryWrapper = styled(Flex)(
  ({ theme }) => `
  flex-direction: column;
  gap: ${theme.spacing.mini};
  width: 100%;
  
  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    padding: 0 0 0 ${theme.spacing.husky};
  }

  ${MEDIA_QUERIES(theme).xLarge} {
    padding: 0;
  }
`
)
