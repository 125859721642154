import { HOMEPAGE_GPT_ID } from '@/components-page/homepage/advertisement/homepageAdSlots'
import { StyledGptLeaderBase } from '@/components-page/homepage/advertisement/styled'

export const GptLeaderBase = () => {
  return (
    <StyledGptLeaderBase
      id={HOMEPAGE_GPT_ID.LEADER_BASE}
      data-testid={HOMEPAGE_GPT_ID.LEADER_BASE}
    />
  )
}
