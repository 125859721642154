import { useGetHomeFeedAdsQuery } from '@kijiji/generated/graphql-types'
import { useSession } from 'next-auth/react'
import { type FC, type PropsWithChildren, useMemo } from 'react'

import { createHomepageGptConfig } from '@/components-page/homepage/advertisement/createHomepageGptConfig'
import { generateGptTargetingValues } from '@/components-page/homepage/advertisement/generateGptTargetingValues'
import { ALL_CANADA_LOCATION_ID } from '@/constants/location'
import { getLocationPath } from '@/domain/location/getLocationPath'
import { AdsWrapper } from '@/features/advertisement/components/ads-wrapper/AdsWrapper'
import { prebidConfig } from '@/features/advertisement/components/prebid/prebidConfig'
import useCookiePreferences from '@/hooks/useCookiePreferences'
import { useLocale } from '@/hooks/useLocale'

type HomepageAdsWrapperProps = PropsWithChildren & {
  locationId: number
  shouldShowHomepageFeed: boolean
}

export const HomepageAdsWrapper: FC<HomepageAdsWrapperProps> = ({
  locationId,
  shouldShowHomepageFeed,
  children,
}) => {
  const { routeLocale } = useLocale()
  const { status } = useSession()
  const { hasUserOptedOutTargeting = false } = useCookiePreferences()

  const { data: homeFeedAdsData } = useGetHomeFeedAdsQuery()
  const numOfHomeFeedAds = homeFeedAdsData?.homeFeedAds.adSlots || 0

  const locationPaths =
    locationId === ALL_CANADA_LOCATION_ID ? undefined : getLocationPath(locationId)

  const gptTargetingValues = useMemo(
    () =>
      generateGptTargetingValues(
        status,
        routeLocale,
        locationId,
        locationPaths,
        hasUserOptedOutTargeting
      ),
    [hasUserOptedOutTargeting, locationId, locationPaths, routeLocale, status]
  )

  const homepageGptConfig = useMemo(
    () =>
      createHomepageGptConfig(
        gptTargetingValues,
        routeLocale,
        shouldShowHomepageFeed,
        numOfHomeFeedAds
      ),
    [gptTargetingValues, routeLocale, shouldShowHomepageFeed, numOfHomeFeedAds]
  )

  /**
   * We need to make React-Advertising wait for the GPT scripts to be loaded
   * Otherwise it will throw a window.googletag.cmd undefined error
   */
  return (
    <AdsWrapper
      gptConfig={homepageGptConfig}
      prebidConfig={prebidConfig}
      skipAdsense
      skipBlockthrough
    >
      {children}
    </AdsWrapper>
  )
}
