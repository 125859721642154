import SvgFooterKijijiCentral from '@kijiji/icons/src/icons/FooterKijijiCentral'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import { KijijiCentralCardBody } from '@/components/homepage/kijiji-central-gallery/styled'
import { GalleryCard } from '@/components/shared/gallery/GalleryCard'
import { GalleryHeaderWrapper } from '@/components/shared/gallery/GalleryHeaderWrapper'
import { GalleryScrollableContainer } from '@/components/shared/gallery/GalleryScrollableContainer'
import { GalleryWrapper } from '@/components/shared/gallery/GalleryWrapper'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { HeadlineText } from '@/ui/atoms/headline-text'

const KIJIJI_CENTRAL_CARD_IMAGES: { [x: string]: string } = {
  card_1: `/next-assets/images/homepage/kijiji-central-save-money-from-home.webp`,
  card_2: `/next-assets/images/homepage/kijiji-central-find-perfect-apartment.webp`,
  card_3: `/next-assets/images/homepage/kijiji-central-8-essential-tools.webp`,
}

export const KijijiCentralGallery = () => {
  const { t } = useTranslation('home')
  const { colors, spacing } = useTheme()

  const handleCardTracking = (title: string) => {
    trackEvent({ action: GA_EVENT.KijijiCentralClick, label: `article=${title}` })
  }

  return (
    <GalleryWrapper flexDirection="column" gap={spacing.default}>
      <GalleryHeaderWrapper>
        <HeadlineText as="h2" color={colors.grey.primary} size="medium" weight="regular">
          {t('kijiji_central.title')}
        </HeadlineText>
      </GalleryHeaderWrapper>

      <GalleryScrollableContainer>
        {Array.from({ length: 3 }).map((_, index) => {
          const translationKey = `kijiji_central.card_${index + 1}`
          const title = t(`${translationKey}.title`)

          return (
            <GalleryCard
              hasGradient
              href={t(`${translationKey}.link`)}
              image={{ src: KIJIJI_CENTRAL_CARD_IMAGES[`card_${index + 1}`], alt: '' }}
              index={index}
              key={`kijiji-central-link-${index}`}
              onClick={() => handleCardTracking(title)}
              useLegacyLgDesktopBreakpoint
            >
              <KijijiCentralCardBody>
                <HeadlineText as="h3" size="xSmall" weight="regular" color={colors.white}>
                  {title}
                </HeadlineText>

                <SvgFooterKijijiCentral aria-hidden color={colors.white} />
              </KijijiCentralCardBody>
            </GalleryCard>
          )
        })}
      </GalleryScrollableContainer>
    </GalleryWrapper>
  )
}
