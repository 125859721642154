import styled from 'styled-components'

export const KijijiCentralCardBody = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg {
    flex-shrink: 0;
    height: 3rem;
    width: 3rem;
  }

  & h3 {
    padding-right: 3rem;

    /* Text will turn to ellipsis after 2 lines */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }
`
