import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { useTheme } from 'styled-components'

import { SignInBannerContainer } from '@/features/auth/components/sign-in-banner/styled'
import { isUserAuthenticated } from '@/features/auth/constants/user'
import { useSignin } from '@/features/auth/hooks/useSignin'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

export const SignInBanner = () => {
  const { t } = useTranslation('home')
  const { colors, spacing } = useTheme()
  const { asPath } = useRouter()
  const { status } = useSession()

  const [goToSignIn, setGoToSignIn] = useState(false)
  useSignin({ callbackUrl: asPath, goToSignIn })

  const goToLogin = () => {
    trackEvent({ action: GA_EVENT.LoginBegin })
    setGoToSignIn(true)
  }

  if (isUserAuthenticated(status)) return null

  return (
    <SignInBannerContainer alignItems="center" justifyContent="center" flexDirection="column">
      <HeadlineText as="h2" color={colors.white} size="large" textAlign="center">
        {t('sign_in.title')}
      </HeadlineText>

      <Spacing mTop={spacing.defaultSmall} mBottom={spacing.large}>
        <BodyText color={colors.white} size="medium" textAlign="center" weight="regular">
          {t('sign_in.subtitle')}
        </BodyText>
      </Spacing>

      <Button onClick={goToLogin} variant="secondary" size="medium">
        {t('sign_in.cta')}
      </Button>
    </SignInBannerContainer>
  )
}
