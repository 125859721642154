import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgInfoToolTip: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipRule="evenodd" fillRule="evenodd">
        <path
          d="M12 4c-4.417 0-8 3.583-8 8s3.583 8 8 8 8-3.583 8-8c-.083-4.333-3.583-8-8-8z"
          fill="#8e909b"
        />
        <path
          d="M12.684 13.273a.728.728 0 01-1.455 0l-.005-.382c0-.47.076-.856.233-1.16.155-.302.466-.644.933-1.024.467-.378.747-.627.838-.745a.991.991 0 00.212-.615 1.02 1.02 0 00-.375-.793c-.249-.222-.584-.332-1.007-.332-.407 0-.747.116-1.022.347-.273.233-.46.586-.564 1.06a.74.74 0 01-1.466-.185c.043-.681.332-1.256.869-1.732C10.412 7.236 11.118 7 11.99 7c.92 0 1.65.24 2.193.72S15 8.755 15 9.394c0 .35-.098.686-.298 1-.2.315-.625.743-1.279 1.286-.337.28-.547.507-.629.677-.08.17-.117.475-.11.916zM12 15A1 1 0 1112 16.998 1 1 0 0112 15z"
          fill="#fff"
        />
      </g>
    </Icon>
  )
}
export default SvgInfoToolTip
