import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { MEDIA_QUERY_HP_LARGE_DESKTOP } from '@/components/homepage/styled'
import { GalleryWrapper } from '@/components/shared/gallery/GalleryWrapper'
import { PAGE_CONTAINER_HORIZONTAL_MOBILE_SPACING } from '@/components/shared/page-container/styled'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const HOME_FEED_ITEMS_PER_ROW_DESKTOP = 4
export const HOME_FEED_ITEMS_PER_ROW_MOBILE = 2

export const HomepageFeedWrapper = styled(GalleryWrapper)`
  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    padding: 0;
  }
`

export const HomeFeedListContainer = styled.div(
  ({ theme }) => `
  grid-template-columns: repeat(${HOME_FEED_ITEMS_PER_ROW_MOBILE}, minmax(0, 1fr));
  gap: 1.6rem;
  display: grid;

  ${MEDIA_QUERIES(theme).medium} {
    grid-template-columns: repeat(${HOME_FEED_ITEMS_PER_ROW_DESKTOP}, minmax(0, 1fr));
  }
`
)

/**
 * A custom style needs to be done for this carousel inside of the feed
 * In this case, the carousel should still touch the sides of the screen on certain
 * viewports even though the rest of the grid components shouldn't.
 *  */
const homepageFeedGalleryFullLine = (theme: ThemeProps) => `
  margin: ${theme.spacing.default} -${PAGE_CONTAINER_HORIZONTAL_MOBILE_SPACING} ${
    theme.spacing.mini
  };

  ${MEDIA_QUERIES(theme).medium} {
    margin: 0 -${theme.spacing.husky};
  }

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    margin: 0 0 0 -${theme.spacing.husky};
    padding: 0 0 0 ${theme.spacing.husky};
  }

  ${MEDIA_QUERIES(theme).large} {
    padding: 0;
  }

  ${MEDIA_QUERIES(theme).xLarge} {
    padding: 0 0 0 ${theme.spacing.husky};
  }
`

export const HomepageFeedFullLine = styled.div<{ isGallery?: boolean }>(
  ({ theme, isGallery }) => `
  grid-column-start: 1;
  grid-column-end: span ${HOME_FEED_ITEMS_PER_ROW_MOBILE};
  ${isGallery ? homepageFeedGalleryFullLine(theme) : ''}

  ${MEDIA_QUERIES(theme).medium} {
    grid-column-start: 1;
    grid-column-end: span ${HOME_FEED_ITEMS_PER_ROW_DESKTOP};
  }
`
)
