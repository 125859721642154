import { type FC } from 'react'

import { HOMEPAGE_GPT_ID } from '@/components-page/homepage/advertisement/homepageAdSlots'
import { StyledGptLeaderBaseHomeFeed } from '@/components-page/homepage/advertisement/styled'

type HomepageFeedAdSlotProps = {
  /**
   * Definition of the ad counter placed between home feed items
   * Used to append the ad slot id,from 1-10
   * eg. gpt-leader-base-1, gpt-leader-base-2 ... gpt-leader-base-10
   */
  count: number
}

export const HomepageFeedAdSlot: FC<HomepageFeedAdSlotProps> = ({ count }) => {
  return <StyledGptLeaderBaseHomeFeed id={`${HOMEPAGE_GPT_ID.LEADER_BASE}-${count}`} />
}
