import { type ALL_SIZES, type EXTENSION, getImageBySize } from '@kijiji/image'
import Image, { type ImageProps, type StaticImageData } from 'next/image'
import { useState } from 'react'

import notFoundImage from '~/public/next-assets/images/not-found.webp'

export type ImageYAMSProps = Omit<ImageProps, 'src'> & {
  src?: string | null | undefined | StaticImageData
  extension?: EXTENSION
  width?: ALL_SIZES
}

export const prepareImage = (
  src: ImageYAMSProps['src'],
  width: ImageYAMSProps['width'],
  extension: ImageYAMSProps['extension']
) => {
  if (!src) {
    return notFoundImage
  }
  /** Only external images should be passed as string. Static images should be of type StaticImageData **/
  if (typeof src === 'string') {
    return getImageBySize(src, width || 200, extension, true)
  }
  return src
}

/**
 * A custom wrapper around Next.js Image component to provide support for custom image formats and sizes
 *
 * @example
 *  // Use ImageYAMS like you would use Next.js Image component:
 *  <ImageYAMS src="/path/to/image.jpg" alt="An image" width={200} height={200} extension="WEBP" />
 *
 * @param {ImageProps & ImageYAMSProps} props - The props to pass to the ImageYAMS component
 * @returns {ReactElement} The rendered ImageCustom component
 */
export const ImageYAMS: React.FC<ImageYAMSProps> = ({ src, width, extension = 'JPG', ...rest }) => {
  const [imageSrc, setImageSrc] = useState(prepareImage(src, width, extension))

  const modifiedProps: ImageProps = {
    src: imageSrc,
    ...(width && { width }),
    ...(typeof src === 'string' && { unoptimized: true }),
    ...rest,
    onError: () => {
      setImageSrc(notFoundImage)
    },
  }

  return <Image {...modifiedProps} alt={rest.alt ?? ''} />
}
