import { useGetCampaignsQuery } from '@kijiji/generated/graphql-types'

import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { getCustomCampaignPlatform, parseCampaigns } from '@/domain/customCampaigns'
import { isValidLocation } from '@/domain/location/isValidLocation'
import { type BrandedTileData, CustomCampaignName } from '@/types/customCampaigns'
import { sendToLogger } from '@/utils/sendToLogger'

type BrandedTileResponse = {
  campaign: BrandedTileData
}

export const useBrandedTileCampaign = (locationId: number): BrandedTileResponse => {
  const filterAmount = 1
  const platform = getCustomCampaignPlatform()

  const { data } = useGetCampaignsQuery({
    skip: !isValidLocation(locationId),
    fetchPolicy: 'cache-first',
    variables: {
      categoryId: ALL_CATEGORIES_ID_NUM,
      customType: CustomCampaignName.BRANDED_TILE,
      filterAmount,
      locationId,
      placement: 'custom',
      platform,
    },
    onError: (err) => {
      sendToLogger(err, { fingerprint: ['useBrandedTileCampaign'] })
    },
  })

  /**
   * The custom campaigns are returned as a string and will need to be parsed to the expected
   * format for the campaign requested
   */
  const campaign = parseCampaigns<BrandedTileData>({ campaigns: data?.campaigns, filterAmount })[0]

  return { campaign }
}
