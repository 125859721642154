import styled from 'styled-components'

type BreakpointProps = {
  breakpoint: number
  display?: 'block' | 'flex'
}

/**
 * Display the element only when the screen is at or larger than the specified breakpoint.
 * This is a copy of the ShowAtOrLarger component to enable legacy breakpoints.
 * @param breakpoint The breakpoint to use.
 * @param display The display value to use. Defaults to `block`.
 */
export const ShowAtOrLargerHP = styled.div<BreakpointProps>(({ breakpoint, display = 'block' }) => {
  return `
    display: none;

    @media screen and (min-width: ${breakpoint}px) {
      display: ${display};
    }
  `
})

/**
 * Hide the element only when the screen is at or larger than the specified breakpoint.
 * This is a copy of the HideAtOrLarger component to enable legacy breakpoints.
 * @param breakpoint The breakpoint to use.
 * @param display The display value to use. Defaults to `block`.
 */
export const HideAtOrLargerHP = styled.div<BreakpointProps>(({ breakpoint, display = 'block' }) => {
  return `
  display: ${display};
  
  @media screen and (min-width: ${breakpoint}px) {
    display: none;
  }
  `
})
