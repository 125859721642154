import styled from 'styled-components'

import { Flex } from '@/ui/atoms/flex'

export const SignInBannerContainer = styled(Flex)(
  ({ theme }) => `
  background: ${theme.colors.purple.primary};
  padding: ${theme.spacing.xLarge};
  border-radius: ${theme.borderRadius.small};

  & button {
    padding: 1.5rem 3.5rem;
  }
`
)
