import styled from 'styled-components'

import { PanelProps } from './Panel'

export const PanelContainer = styled.div<PanelProps>(
  ({ $alignItems, $isFullWidth, theme }) => `
  align-items: ${$alignItems};
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.small};
  box-shadow: 0px 1px 5px rgba(52, 55, 70, 0.1);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: ${theme.spacing.large};
  width: ${$isFullWidth ? '100%' : 'fit-content'};
`
)
