import styled from 'styled-components'

export const PriceDropContainer = styled('div')(
  ({ theme }) => `
  width: 100%;
  box-shadow: ${theme.boxShadow['shadow-2']};
  border: 1px solid ${theme.colors.grey.light4};
  padding: 8px;
  border-radius: 8px;
  display: flex;
  position: relative;

  img {
    margin-right: 8px;
  }
`
)

export const Title = styled.h3(
  ({ theme }) => `
  font-size: 1.8rem;
  color: ${theme.colors.grey.primary};
  font-weight: ${theme.fontWeight.semiBold};
  margin-bottom: 8px;
`
)

export const Body = styled.p(
  ({ theme }) => `
  font-size: 1.6rem;
  color: ${theme.colors.grey.primary};
  font-weight: ${theme.fontWeight.regular};
  margin-bottom: 8px;
`
)

export const ViewNow = styled.a(
  ({ theme }) => `
  color: ${theme.colors.purple.primary};
  font-weight: ${theme.fontWeight.regular};
  text-decoration: underline;
  margin: 0 8px;
  line-height: 2rem;
  white-space: nowrap;
`
)

export const DismissButton = styled.button(
  ({ theme }) => `
  background-color: transparent;
  cursor: pointer;
  height: ${theme.spacing.default};
  width: ${theme.spacing.default};
  align-self: flex-start;
  position: absolute;
  top: 8px;
  right: 8px;

  > svg {
    height: ${theme.spacing.default};
    width: ${theme.spacing.default};
  }

  &:hover {
    color: ${theme.colors.grey.primary};
  }
`
)
