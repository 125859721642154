import { colors } from '@kijiji/theme'
import Image, { type StaticImageData } from 'next/image'
import { type FC, type PropsWithChildren } from 'react'

import {
  GalleryCardBody,
  GalleryCardContainer,
  GalleryCardGradient,
  GalleryCardImageContainer,
  GalleryCardLink,
} from '@/components/shared/gallery/styled'
import { ImageCustom } from '@/components/shared/image'
import { type UseLegacyLgDesktopBreakpoint } from '@/types/common'
import { BodyText } from '@/ui/atoms/body-text'

export type GalleryCardProps = PropsWithChildren &
  UseLegacyLgDesktopBreakpoint & {
    /**
     * Default style with centralized title with purple background
     */
    centralizedTitle?: string
    /**
     * Defines if card has a gradient in front of the image
     */
    hasGradient?: boolean
    /**
     * Should be set only when centralized title was passed
     * it works so the link is has an accessible aria-label
     */
    hideCentralizedTitle?: boolean
    /**
     * Defines if card is not on a scrollable gallery
     */
    isOnStaticGallery?: boolean
    /**
     * Index of card in the gallery
     */
    index: number
    /**
     * Image to be displayed as background of gallery card
     * */
    image: {
      alt: string
      /**
       * Should be of type StaticImageData if comes from next-assets CDN
       * If src comes from our API, then should accept string
       */
      src: StaticImageData | string
      /**
       * Define if the background image should be fixed in any position
       * @default center
       * */
      position?: 'left' | 'right' | 'top' | 'bottom' | 'center'
    }
    /**
     * Href of the link that wraps the card
     */
    href: string
    /**
     * Defines max card width
     * [desktop, mobile] -> ["20rem", "10rem"]
     */
    maxWidth?: string[]
    /**
     * Function to be trigger on card link click
     */
    onClick?: () => void
  }

/**
 * @description Square card used on scrollable galleries
 */
export const GalleryCard: FC<GalleryCardProps> = ({
  centralizedTitle,
  children,
  hasGradient,
  hideCentralizedTitle,
  href,
  image,
  index,
  isOnStaticGallery,
  maxWidth = [],
  onClick,
  useLegacyLgDesktopBreakpoint,
}) => {
  return (
    <GalleryCardLink
      aria-label={centralizedTitle}
      centralizedTitle={centralizedTitle}
      href={href}
      isOnStaticGallery={isOnStaticGallery}
      key={`kijiji-central-link-${index}`}
      maxWidth={maxWidth}
      onClick={onClick}
      target="_blank"
      useLegacyLgDesktopBreakpoint={useLegacyLgDesktopBreakpoint}
    >
      <GalleryCardContainer centralizedTitle={centralizedTitle}>
        <GalleryCardImageContainer position={image.position}>
          {typeof image.src === 'string' ? (
            <ImageCustom alt={image.alt} src={image.src} data-testid="gallery-card-image" fill />
          ) : (
            /**
             * we can only use placeholder="blur" without blurDataURL
             * for static images (ie next-assets)
             * https://nextjs.org/docs/pages/api-reference/components/image#placeholder
             */
            <Image
              alt={image.alt}
              data-testid="gallery-card-image"
              fill
              src={image.src}
              placeholder="blur"
            />
          )}
        </GalleryCardImageContainer>

        {hasGradient ? <GalleryCardGradient data-testid="gallery-card-gradient" /> : null}

        {centralizedTitle && !hideCentralizedTitle ? (
          <GalleryCardBody
            centralizedTitle={centralizedTitle}
            alignItems="center"
            justifyContent="center"
          >
            <BodyText color={colors.white} size="large" textAlign="center" weight="regular">
              {centralizedTitle}
            </BodyText>
          </GalleryCardBody>
        ) : (
          <GalleryCardBody>{children}</GalleryCardBody>
        )}
      </GalleryCardContainer>
    </GalleryCardLink>
  )
}
